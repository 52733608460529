import { Box } from "@mui/material";
import { Link } from "@remix-run/react";
import { FC } from "react";
import Highlighter from "react-highlight-words";
import BazaarImage from "~/components/BazaarImage";
import { FlexBox } from "~/components/flex-box";
import { Paragraph } from "~/components/Typography";

// ===========================================
type SearchProductCardProps = {
  image: string;
  title: string;
  price: string;
  url: string;
  queryString: string;
  onClose: any;
};
// ===========================================

const SearchProductCard: FC<SearchProductCardProps> = ({
  image,
  title,
  price,
  url,
  queryString,
  onClose,
}) => {
  const handleOnClick = () => {
    onClose(false);
  };
  return (
    <Link to={url} onClick={handleOnClick}>
      <FlexBox
        mb={2}
        gap={2}
        alignItems="center"
        sx={{
          " & a": { flexShrink: 0 },
          ":last-of-type": { mb: 0 },
          "& img": { transition: "0.3s" },
          // ":hover": { img: { transform: "scale(1.1)" } },
          border: "0.5px solid #ececec",
          borderRadius: "8px",
        }}
      >
        <Box maxWidth={100}>
          <BazaarImage width="100%" alt="product" src={image} />
        </Box>

        <Box>
          {/* <Link to={url} onClick={handleOnClick}> */}
          <Paragraph fontSize={16}>
            <Highlighter
              searchWords={[queryString]}
              autoEscape={true}
              textToHighlight={title}
            />
          </Paragraph>
          {/* </Link> */}
          {title !== "Шатахуун Эко Ойл" && title !== "Зүрх мартахгүй төсөл" && (
            <Paragraph fontWeight={700}>{price}</Paragraph>
          )}
        </Box>
      </FlexBox>
    </Link>
  );
};

export default SearchProductCard;
