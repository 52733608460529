import {
  Box,
  CardMedia,
  Dialog,
  FormControl,
  Grid,
  InputBase,
} from "@mui/material";
import { useFetcher, useNavigate } from "@remix-run/react";
import React, { FC, useEffect, useState } from "react";
import { SearchHistory, SearchHits } from "~/models";
import { useRootLoader } from "~/utils/use-root-loader";
import { useDebounce } from "~/utils/useDebounce";
import { SearchOutlinedIcon } from "./SearchBox";
import SearchHitResultsCard from "./SearchHitResultsCard";
import SearchLocalHistory from "./SearchLocalHistory";

// ====================================================
type SearchDialogProps = {
  placeholder: string;
  url: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onClose: () => void;
  defaultValue?: string;
};
// ====================================================

const SearchDialog: FC<SearchDialogProps> = ({
  defaultValue,
  url,
  placeholder,
  isOpen,
  setIsOpen,
  onClose,
}) => {
  let [query, setQuery] = useState("");
  let [debouncedQuery, isDebouncing] = useDebounce(query, 500);
  const { banners } = useRootLoader();
  const searchBanner = banners.find(
    (banner) => banner.code === "search_banners"
  );
  const fetcher = useFetcher();
  const navigate = useNavigate();

  let searchHistoryNew: SearchHistory[] = [];
  const history = () => {
    if (typeof window !== "undefined") {
      const searchHistory = {
        text: query,
        uri: encodeURIComponent(query),
      } as SearchHistory;

      let searchHistoryOld =
        JSON.parse(localStorage.getItem("searchHistory")!) || [];
      let index = searchHistoryOld.findIndex((item) => item.text === query);
      if (index > -1) {
        searchHistoryOld.splice(index, 1);
        searchHistoryOld.unshift(searchHistory);
      } else if (searchHistoryOld.length > 5) {
        searchHistoryOld.pop();
        searchHistoryOld.unshift(searchHistory);
      } else {
        searchHistoryOld.unshift(searchHistory);
      }
      searchHistoryNew = [...searchHistoryOld];
      localStorage.setItem("searchHistory", JSON.stringify(searchHistoryOld));
    }
  };

  let searchLocalHistory = [];

  if (typeof window !== "undefined") {
    searchLocalHistory =
      JSON.parse(localStorage?.getItem("searchHistory")!) || "";
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsOpen(false);
    history();
    navigate(`${url}?q=${encodeURIComponent(query)}`);
  };

  const renderResults = () => {
    if (fetcher.data) {
      if (debouncedQuery) {
        return (
          <SearchHitResultsCard
            data={fetcher.data as SearchHits}
            onClose={setIsOpen}
          />
        );
      }

      return (
        <SearchLocalHistory
          data={searchLocalHistory}
          url={url}
          onClose={() => setIsOpen(false)}
        />
      );
    } else {
      return (
        <SearchLocalHistory
          data={searchLocalHistory}
          url={url}
          onClose={() => setIsOpen(false)}
        />
      );
    }
  };

  useEffect(() => {
    if (debouncedQuery && debouncedQuery != "q") {
      let data = {
        q: debouncedQuery,
      };

      // if (branchCode) {
      //   data["b"] = branchCode;
      // }

      fetcher.submit(data, { method: "get", action: "/search" });
    }
  }, [debouncedQuery]);

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            top: 30,
            m: 0,
            bgcolor: "#fff",
            maxHeight: "700px",
          },
        }}
      >
        <Box sx={{ flexGrow: 1, bgColor: "#000" }}>
          <Grid container>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <fetcher.Form
                  action={url}
                  method="get"
                  onSubmit={handleOnSubmit}
                >
                  <input type="hidden" name="q" value={debouncedQuery} />
                  <FormControl id="search-form" fullWidth variant="outlined">
                    <InputBase
                      sx={{ m: 1, flex: 1 }}
                      placeholder={placeholder}
                      onChange={(e) => setQuery(e.target.value)}
                      // onSubmit={(e) => navigate(`/s?q=${debouncedQuery}`) }
                      autoFocus={true}
                      defaultValue={query}
                      inputProps={{
                        sx: {
                          height: 44,
                          paddingRight: 0,
                          borderRadius: 0,
                          color: "black.50",
                          overflow: "hidden",
                        },
                      }}
                      startAdornment={<SearchOutlinedIcon fontSize="small" />}
                    />
                  </FormControl>
                </fetcher.Form>
              </Grid>

              {/* render search results */}
              {renderResults()}
            </Grid>
            <Grid item xs={4}>
              <CardMedia
                component="img"
                alt="search_banner"
                src={searchBanner?.banner[0].photoUrl}
                sx={{
                  borderRadius: 0.5,
                  p: 1.5,
                }}
              />
              {/* <CardMedia
                component="img"
                width="300px"
                height="300px"
                alt="123 Main St, Phoenix, AZ cover"
                src="https://preprod-magento-backend.nomin.mn/media/neosolax/banner/ded1.png"
                sx={{
                  borderRadius: 0.5,
                  width: { xs: '100%', sm: 300 },
                  mr: { sm: 1.5 },
                  mb: { xs: 1.5, sm: 0 },
                  pb: 2
                }}
              /> */}
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default SearchDialog;
