import { Box, Grid, List, ListItemText } from "@mui/material";
import { Link } from "@remix-run/react";
import { FC } from "react";
import { SearchHitProduct, SearchHits } from "~/models";
import SearchProductCard from "./SearchProductCard";

// ===========================================
type SearchHitResultsProps = {
  data: SearchHits;
  onClose: any;
};
// ===========================================

const SearchHitResultsCard: FC<SearchHitResultsProps> = ({
  data,
  onClose,
}) => {
  // handleOnClick => setIsOpen => false;
  // // setIsOpen(false)
  // const navigate = useNavigate();
  // navigate('category.url');
  // const handleOnClick = (Event) =>(
  //   Event.handleOnClick = setIsOpen => false

  // );
  const handleOnClick = () => {
    onClose(false);
  };
  return (
    <>
      {data?.categories?.length > 0 && (
        <>
          <Box sx={{ px: 2, fontWeight: 600, fontSize: "17px" }}>
            Ангиллын хайлтын илэрц
          </Box>
          <List
            component="nav"
            key="search-hits-categories"
            sx={{ padding: "0 30px", color: "#2d68c4" }}
          >
            {data.categories.map((category) => (
              <ListItemText key={`list-item-category-${category.id}`}>
                <Link
                  key={`category-${category.id}`}
                  to={category.url}
                  onClick={handleOnClick}
                  style={{
                    color: "#2d68c4",
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  {`${data?.query} -> ${category.name}`}
                </Link>
              </ListItemText>
            ))}
          </List>
        </>
      )}

      {/* TODO: Брэнд хуудсыг хөгжүүлсэний дараа оруулах */}
      {/* {data?.brands?.length > 0 && (
        <>
          <Typography sx={{px: 2}}>Брэндийн хайлтын гүйцээлт</Typography>
          <List component="nav" key="search-hits-brands">
            {data.brands.map((brand) => (
              <ListItemText key={`list-item-brand-${brand.id}`}>
                <Link to={brand.url} className={classes.link}>{`${query} in ${brand.name}`}</Link>
              </ListItemText>
            ))}
          </List>
        </>
      )} */}

      <Box sx={{ px: 2, fontWeight: 600, fontSize: "17px" }}>Хайлтын илэрц</Box>
      <List sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {data &&
            data.items &&
            data.items.map((product: SearchHitProduct) => (
              <Grid item lg={6} sm={6} xs={12} key={product.id}>
                <SearchProductCard
                  queryString={data?.query}
                  url={product.url}
                  image={product.thumbImage}
                  title={product.name}
                  price={product.formattedPrice}
                  onClose={onClose}
                />
              </Grid>
            ))}
        </Grid>
      </List>
    </>
  );
};

export default SearchHitResultsCard;
