import { Box, Grid } from "@mui/material";
import { Link } from "@remix-run/react";
import { FC } from "react";
import BazaarImage from "../BazaarImage";

// ===========================================
type SearchLocalHistoryProps = {
  data: any[];
  url: string;
  onClose: () => void;
};
// ===========================================


const SearchLocalHistory: FC<SearchLocalHistoryProps> = ({
  data,
  url,
  onClose,
}) => {
  return (
    <Grid item xs={12} pl={2}>
      <Box sx={{ fontWeight: 600, fontSize: "17px" }}>
        Хайлтын түүх
      </Box>
      {(data || []).map((item: any, index: number) => (
        <Link
          key={`slh-item-${index}`}
          to={`${url}?q=${item.text}`}
          onClick={onClose}
        >
          <Box sx={{ p: 1, display: "flex" }}>
            <BazaarImage
              src="/assets/images/icons/clock-circular-outline.svg"
              sx={{ mr: 1 }}
            />
            {item.text}
          </Box>
        </Link>
      ))}
    </Grid>);
};

export default SearchLocalHistory;