/* eslint-disable react-hooks/exhaustive-deps */
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { Box, Card, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "@remix-run/react";
import React, { useCallback, useRef, useState, type FC } from "react";
import SearchDialog from "./SearchDialog";

// styled components
// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 6,
}));

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(() => ({
  zIndex: 99,
  top: "100%",
  width: "100%",
  position: "absolute",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
}));

const SearchBox: FC = () => {
  const parentRef = useRef();
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search] = useSearchParams();

  let searchPlaceholder = "Та юу хайж байна ....";
  let searchUrl = "/s";

  const handleOnClick = useCallback((event) => {
    setIsOpen(true);
    // INFO: доорх утгыг шууд арилгавал SearchDialog дээр
    // гарч ирэх эхний үсэг байхгүй болох тул 5 секундын delay авав
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.value = "";
      }
    }, 5000);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <SearchDialog
        url={searchUrl}
        placeholder={searchPlaceholder}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleClose}
        defaultValue={(inputRef.current?.value || "") as string}
      />

      <Box
        position="relative"
        flex="1 1 0"
        // maxWidth="670px"
        mx="auto"
        {...{ ref: parentRef }}
      >
        <TextField
          fullWidth
          variant="outlined"
          inputRef={inputRef}
          placeholder={search.get("q") || searchPlaceholder || "Хайх..."}
          onChange={handleOnClick}
          onClick={handleOnClick}
          InputProps={{
            sx: {
              height: 44,
              paddingRight: 0,
              borderRadius: 300,
              fontWeight: "200",
              // color: "grey.700",
              color: "#2b3445",
              overflow: "hidden",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main",
              },
              background: "#fff",
            },
            startAdornment: <SearchOutlinedIcon fontSize="small" />,
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default SearchBox;
